import React from 'react';
import { Header } from '../components/header';
import { Split } from '../components/split';
import { Links } from '../components/links';
import { Feed } from '../components/feed';
import { Footer } from '../components/footer';
import bag from '../assets/bag-circle-bkg-s.jpg';
import bag2 from '../assets/bag-2-circle-bkg-s.jpg';
import me from '../assets/me-low-res.jpg';
import '../App.css';

function App() {
  const marca = [
    <>
      <h2>Designs únicos e sustentáveis</h2>
      <p>
        Na Cliché nós criamos uma moda sustentável. Através do upcycling de
        tecidos e materiais criamos designs inovadores e modernos, levando em
        conta o impacto ambiental dos nossos produtos.
      </p>
      <p>Sua moda mais bonita em um mundo mais bonito.</p>
    </>,
    <img src={bag} className="split-image" alt="Bolsa tecido metálico" />,
  ];

  const upcycling = [
    <img src={bag2} className="split-image" alt="Bolsa upcycling" />,
    <>
      <h2>Upcycling</h2>
      <p>
        O upcycling é a reutilização de materiais para criar novos produtos.
        Dando uma segunda vida a materiais de qualidade que seriam descartados.
      </p>
      <p>
        A indústria da moda é uma das maiores poluidoras do mundo. A
        sustentabilidade e a redução de resíduos texteis são preocupações
        centrais para nós da Cliché. Nossas bolsas são duráveis e produzidas com
        tecidos de qualidade reaproveitados.
      </p>
      <p>
        Comprando produtos da Cliché você se veste bem e ajuda a criar um futuro
        melhor, com menos resíduos texteis.
      </p>
    </>,
  ];

  const nos = [
    <>
      <h2>Design e produção</h2>
      <p>
        Por trás da marca da Cliché está Maria do Carmo, nossa designer e
        artesã. Ela é responsavel pela criação de cada um de nossos produtos. Da
        concepção da idéia até a execução.
      </p>
      <p></p>
      <p>
        Nós criamos e produzimos todos nossos produtos no nosso atelier no Rio
        de Janeiro.
      </p>
    </>,
    <img
      src={me}
      className="split-image"
      alt="Maria do Carmo"
      style={{ width: 300 }}
    />,
  ];

  return (
    <div>
      <Links />
      <Header />
      <div className="container">
        <Split left={marca[0]} right={marca[1]} />
        <Split left={upcycling[0]} right={upcycling[1]} reverse />
        <Split left={nos[0]} right={nos[1]} />
        <Feed />
      </div>
      <Footer />
    </div>
  );
}

export default App;
