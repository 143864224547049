import React from 'react';
import '../App.css';
import logo from '../assets/logo.svg';

export const Header = () => (
  <header className="app-header">
    <div className="container">
      <img src={logo} className="app-logo" alt="logo Cliche bolsas" />
    </div>
    <div className="bkg"></div>
  </header>
);
