import React from 'react';
import '../App.css';

export const Links = () => (
  <ul className="links">
    <li>
      <a href="https://wa.me/5521991018282" target="_blank" rel="noreferrer">
        WhatsApp
      </a>
    </li>
    <li>
      <a
        href="https://www.elo7.com.br/clichebolsas"
        target="_blank"
        rel="noreferrer"
      >
        Elo7
      </a>
    </li>
    <li>
      <a
        href="https://www.instagram.com/clichebolsas/"
        target="_blank"
        rel="noreferrer"
      >
        Instagram
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/cliche.bolsas">Facebook</a>
    </li>
  </ul>
);
