import React, { ReactElement } from 'react';
import '../App.css';

export const Split = ({
  left,
  right,
  reverse,
}: {
  left: ReactElement;
  right: ReactElement;
  reverse?: boolean;
}) => (
  <div className="split">
    <div className={reverse ? 'order1' : ''}>{left}</div>
    <div>{right}</div>
  </div>
);
