import React from 'react';
import '../App.css';

export const Footer = () => (
  <footer className="footer">
    <div className="container">
      Cliche Bolsas - 2022 - Rio de Janeiro, Brasil
    </div>
  </footer>
);
