import React from 'react';
import '../App.css';
import img1 from '../assets/feed/1.jpg';
import img2 from '../assets/feed/2.jpg';
import img3 from '../assets/feed/3.jpg';
import img4 from '../assets/feed/4.jpg';
import img5 from '../assets/feed/5.jpg';
import img6 from '../assets/feed/6.jpg';

export const Feed = () => (
  <>
    <h2>Novidades</h2>
    <div className="feed-wrapper">
      <ul className="feed">
        <li className="img1"></li>
        <li className="img2"></li>
        <li className="img3"></li>
        <li className="img4"></li>
        <li className="img5"></li>
        <li className="img6"></li>
      </ul>
    </div>
  </>
);
